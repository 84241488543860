import Vue from 'vue'
import App from './App.vue'
import '@/plugins'
import router from './router'
import store from './store'
import '@/core/filters'
import '@/assets/styles/main.scss'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({
      Vue,
      tracing: true
    }), new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
