import Vue from 'vue'
import { formatNumber, formatDate, formatTime } from './helper-functions'

Vue.filter('formaNumber', (value, digits) => {
  if (!value || value === '-') {
    return value
  }
  if (Number.isNaN(value)) {
    return value
  }
  return formatNumber(value, digits)
})

Vue.filter('formatDate', (value, options = { month: 'long', day: 'numeric', year: 'numeric' }) => {
  if (!value || value === '-') {
    return value
  }
  return formatDate(value, options)
})

Vue.filter('formatTime', (value, options = { hour: 'numeric', minute: 'numeric' }) => {
  if (!value || value === '-') {
    return value
  }
  return formatTime(value, options)
})
